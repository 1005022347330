<template>
  <header class="header open" id="header" :class="{active: showNav, header_new: showBg}" @mouseleave="()=>showNav = false">
    <div class="header_open">
      <img @click="skipRoute('','index')" class="logo" :src="(showBg|showNav)?logo1 : logo2">
      <div class="nav">
        <ul class="nav_list">
          <li class="menu_item" :id="index" :class="{active1: showDropdown==index && showNav,active2: navClicked==index}" v-for="(item,index) in dropDown"
              :key="index" @mouseenter="()=>{showDropdown=index, showNav = true}">
            <span class="menu_item_title title nav-bar" @click="skipRoute(item,'pc',index,'','')">{{item.name}}</span>
<!--            <ul class="sub_list" :class="{ul_active: showDropdown==index}" v-if="showDropdown==index && showNav">-->
<!--            <div class="sub_list_outer" v-if="showDropdown==index && showNav">-->
            <div class="sub_list_outer" v-if="showDropdown==index && showNav">
<!--              <ul class="sub_list" :class="{ul_active: showDropdown==index}" v-if="showDropdown==index && showNav">-->
              <ul class="sub_list" :class="{ul_active: showDropdown==index}">
                <li :class="{sub_test: list.child,sub_test2: !list.child, more_list: index==1}" v-for="(list,i) in item.list" :key="i">
                  <div class="title" @click="skipChildRoute(item, list, 'pc')"><i class="iconfont" :class="list.icon" v-if="!list.child"></i><span class="name nav-bar">{{list.name}}</span></div>
                  <div v-if="list.child">
                    <div class="li nav-bar" v-for="(li,l) in list.child" :key="l" @click="skipRoute(item,'pc',index,li,list)">
                      <i class="iconfont icon-dian4"></i>
                      <span class="nav-bar">{{li.name}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="down_drawer" v-if="showNav" @mouseleave="()=>showNav = false"></div>
<!--    <div class="down_drawer"></div>-->
    <div class="menu_btn">
      <div class="inner">
        <el-button text @click="openDrawer"><img src="@/assets/threeline.png" alt=""></el-button>
      </div>
    </div>
    <el-drawer v-model="drawer" direction="rtl" size="100%">
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(item,index) in dropDown" :key="index" :name="item.id" :title="item.name">
          <div class="three-li" v-for="item2 in item.list" :key="item2">
            <div class="name" @click="skipChildRoute(item, item2, 'mobile')"><i class="iconfont" :class="item2.icon" v-if="!item2.child"></i>{{item2.name}}</div>
            <ul class="child" v-if="item2.child">
              <li v-for="(li,l) in item2.child" :key="l" @click="skipRoute(item,'mobile',index,li,item2)"><i class="iconfont icon-dian3"></i>{{li.name}}</li>
            </ul>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>
  </header>
  <div class="content"><router-view></router-view></div>
  <el-backtop class="back-top" />
  <footer>
    <div class="footer_cont">
      <div class="left">
        <div class="left_top">
          <div class="learn_more">了解更多</div>
          <div class="list">
            <div class="com">
              <img alt="" src="../assets/qr_code.png">
              <span>公众号</span>
            </div>
<!--            <div class="com">
              <img alt="" src="../assets/qr_code.png">
              <span>小程序</span>
            </div>-->
          </div>
        </div>
        <div class="left_bottom">
          <div class="pc_page" v-for="(item,index) in dropDown" :key="index" @click="$emit('skipRoute',item)">
            <span class="title nav-bar" @click="skipRoute(item,'pc',index,'','')">{{ item.name }}</span>
            <!--            <div class="footer_link" v-for="item2 in item.list" :key="item2" @click="$emit('skip',item2)">{{item2.name}}</div>-->
          </div>
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item,index) in dropDown.filter(v=>v.name!=='首页')" :key="index" :name="item.id" :title="item.name">
              <div class="footer_link" v-for="item2 in item.list" :key="item2" @click="$emit('skip',item2)">{{item2.name}}</div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="right">
        <div class="message_outer">
          <!--            <img :height="130" :width="130" alt="" src="../assets/qr_code.png">-->
          <div class="message">
            <div class="icon">
              <div class="icon_left">
                <i class="iconfont icon-dizhi1"></i>
                <div style="display: flex;flex-direction: column">
                  <span>地址 Address</span>
                  <span class="cont">四川省成都市金牛区蜀西路46号</span>
                </div>
              </div>
            </div>
            <div class="icon">
              <div class="icon_left">
                <i class="iconfont icon-icon-telephone"></i>
                <div style="display: flex;flex-direction: column">
                  <span>电话 Telephone</span>
                  <span class="cont">19113155307</span>
                </div>
              </div>
            </div>
            <div class="icon">
              <div class="icon_left">
                <i class="iconfont icon-tubiao_youxiang"></i>
                <div style="display: flex;flex-direction: column">
                  <span>邮箱 E-Mail</span>
                  <span class="cont">sales@agilephotonics.com</span>
                </div>
              </div>
            </div>
            <div class="icon">
              <div class="icon_left">
                <i class="iconfont icon-wangluoanquan"></i>
                <div style="display: flex;flex-direction: column">
                  <span>网址 URL</span>
                  <span class="cont">www.agilephotonics.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">Copyright © 2017 - 2024 安捷光通科技成都有限公司. 版权所有</div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import {SolutionList} from '@/api/all_api'
import {reactive, ref, onMounted, watchEffect, onUnmounted} from "vue";
import {useRouter} from 'vue-router';
const router = useRouter()
const drawer = ref(false)
const showNav = ref(false)
const showBg = ref(false) //logo选择
const logo1 = ref(require('@/assets/logo.png'))
const logo2 = ref(require('@/assets/logo-w.png'))
const navClicked = ref(-1) //已经点击
const showDropdown = ref(-1)
const dropDown = reactive([
  {id: 0,name: '首页', route: 'HomeView',label: 'home',list:[{
      icon:'icon-weibiaoti--',
      name: '最新动态',
      id: 'news'
    },{
      icon:'icon-hezuohuoban1-copy-copy-copy',
      name: '合作伙伴',
      id: 'partner'
    }]},
  {id: 1,name: '关于我们', route: 'AboutView',label: 'about',list:[
      {
        icon:'icon-hangyexinwen',
        name: '公司概况',
        id: 'overview'
      },{
        icon:'icon-xiaozhao1',
        name: '我们的优势',
        id: 'advantage'
      },{
        icon:'icon-qiyewenhua1',
        name: '公司文化',
        id: 'culture'
      },{
        icon:'icon-home',
        name: '公司战略',
        id: 'strategy'
      },{
        icon:'icon-diannao-shuju',
        name: '发展历程',
        id: 'history'
      },{
        icon:'icon-gongsirongyu',
        name: '公司荣誉',
        id: 'honor'
      }
    ]},
  {id: 2,name: '解决方案', route: 'SolutionPlan',label: 'solution',list:[
      {
        name: '轨道交通业务',
        child: [
          {
            id: 0,
            name: '数据分析解决方案',
          },
          {
            id: 1,
            name: '铁路安全解决方案',
          },
          {
            id: 2,
            name: '虚拟仿真解决方案',
          }
        ]
      },
      {
        name: '网络安全业务',
        child: [
          {
            id: 0,
            name: '综合防控解决方案',
          },
          {
            id: 1,
            name: '网络测评解决方案',
          },
          {
            id: 2,
            name: '网络运维解决方案',
          },
          {
            id: 3,
            name: '态势感知解决方案',
          }
        ]
      },
      {
        name: '智慧高校业务',
        child: [
          {
            id: 0,
            name: '多态感知解决方案',
          },
          {
            id: 1,
            name: '智慧楼宇解决方案',
          },
          {
            id: 2,
            name: '智慧教学解决方案',
          }
        ]
      },
      {
        name: '光通信业务',
        child: [
          {
            id: 0,
            name: '光缆运维解决方案',
          }
        ]
      }
    ]},
  {id: 3,name: '新闻中心', route: 'NewsView',label: 'news',list:[
      {
        icon:'icon-xinwen1',
        name: '公司新闻',
        id: 'news1'
      },
      {
        icon:'icon-hangyexinwen1',
        name: '行业新闻',
        id: 'news2'
      }
    ]},
  {id: 4,name: '招贤纳士', route: 'JoinUsView',label: 'joinUs',list:[
      {
        icon:'icon-shezhao3',
        name: '社会招聘',
        route: 'SocialRecruitment'
      },
      {
        icon:'icon-xiaozhao1',
        name: '校园招聘',
        route: 'CampusRecruitment'
      }
    ]}
])
const activeNames = ref([])
const openDrawer = () => {
  drawer.value = true
  removeAllEventListeners()
}
const skipRoute = (item,type,index,li,list) => {
  removeAllEventListeners()
  showNav.value = false
  navClicked.value = index
  if(type=='index') {
    router.push({
      name: 'HomeView'
    })
  }else{
    console.log('item=========',item,list)
    router.push({
      name: item.route,
      params:{
        type: li.id?li.id:'',
        sort: list.name,
        solution: li.name
      }
    })
    if(type=='mobile') {
      drawer.value = false
    }
  }
}
const skipChildRoute = (item, list, type) => {
  removeAllEventListeners()
  showNav.value = false
  if(type=='mobile') {
    drawer.value = false
  }
  let routeName = item.label === 'joinUs' ? list.route : item.route
  let routeParams = {
    type: list.id
  }
  router.push({
    name: routeName,
    params: routeParams
  })
}
/*const handleChange = (val) => {
  console.log(111111111,val)
}*/
const removeAllEventListeners = () => {
  // 遍历所有注册的事件监听器并移除
  for (let event in window) {
    if (typeof window[event] === 'function') {
      window.removeEventListener(event, window[event]);
    }
  }
}
const handleScrollTop = () => {
  if (document.documentElement.scrollTop>=400) {
    showBg.value = true
  }else {
    handleResize()
  }
}
const handleResize = () => {
  if (window.innerWidth<=768) {
    showBg.value = true
  }else {
    showBg.value = false
  }
}
onMounted(()=> {
  handleResize()
  window.addEventListener('scroll', handleScrollTop)
  window.addEventListener('resize', handleResize);
  SolutionList().then(res=>{
    dropDown[2].list = res.data.data
  })
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScrollTop);
  window.removeEventListener('resize', handleResize);
});
watchEffect(() => {
  let item = dropDown.find(item => {
    if (router.currentRoute.value.path.includes(item.label)) {
      return router.currentRoute.value.path.includes(item.label)
    }
  })
  navClicked.value = item ? item.id : 0
})
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
  z-index: 3;
  height: 80px;
  align-items: center;
  -webkit-transition: all .5s;
  box-sizing: border-box;
  border-bottom: 1px solid #ffffff3b;
}
.header.open {
  //background-color: #fff;
  //box-shadow: 0px 0px 15px rgba(0, 0, 0, .05);
  color: white;
  padding: 10px 0;
  height: 70px;
  .menu_item::after {
    width: 0%;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    border-radius: 50px;
    background: white;
    content: '';
    -webkit-transition: all .35s;
    color: white;
  }
}
.header.header_new{
  color: #4c4c4c;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .05);

  transition: all 2s;
  //animation:header 0.5s ease-in-out 0s;
  //@keyframes header
  //{
  //  from {opacity: 0;}
  //  to {opacity: 1;}
  //}
  .active2{
    .menu_item_title{
      color: #306ADA;
    }
  }
  .menu_item_title:hover{
    color: #306ADA;
  }
  .menu_item::after {
    width: 0%;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    border-radius: 50px;
    background: #306ADA;
    content: '';
    -webkit-transition: all .35s;
    color: #306ADA;
  }
}
.header.active{
  color: #4c4c4c;
  .active2{
    .menu_item_title{
      color: #306ADA;
    }
  }
  .menu_item_title:hover{
    color: #306ADA;
  }
  .menu_item::after {
    width: 0%;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    border-radius: 50px;
    background: #306ADA;
    content: '';
    -webkit-transition: all .35s;
    color: #306ADA;
  }
}
.header_open{
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  @media screen and (max-width: 1300px){
    padding: 0 20px;
  }
}
.logo {
  width: 180px;
  height: auto;
  cursor: pointer;
  transition: all .6s;
}
ul, li {
  list-style: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.nav {
  width: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  .nav_list {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .menu_item {
      width: auto;
      height: 100%;
      line-height: 3;
      position: relative;
      display: block;
      text-align: center;
      font-size: 16px;
      padding: 10px 15px 0;
      cursor: pointer;
      .menu_item_title{
        padding: 0 5px;
      }
      .title{
        //height: 100%;
        display: inline-block;
      }
    }
    .menu_item_title:hover::after, .menu_item_title:visited::after, .active2::after {
      width: 30%;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .sub_list_outer{
      width: 100%;
      height: 300px;
      position: fixed;
      left: 0;
      top: 70px;
      display: flex;
      justify-content: center;
      //background-color: white;
      //animation: slide-down 0.5s forwards;
      //z-index: 0;
      //@keyframes slide-down {
      //  from{
      //    top: -300px;
      //    opacity: 0;
      //    visibility: hidden;
      //  }
      //  to {
      //    top: 0px; /* 动画结束位置：元素从上方滑到原位置 */
      //    opacity: 1;
      //    visibility: visible;
      //  }
      //}
    }
    .sub_list{
      display: flex;
      width: 1200px;
      height: 100%;
      margin: auto;
      //transform: translate(-70%, 0);
      justify-content: flex-end;
      position: absolute;
      //left: 0;
      z-index: 1;
      text-align: left;
      padding-top: 40px;
      //margin-top: 8px;
      //top: 25px;
      //opacity: 0;
      //visibility: hidden;
      opacity: 1;
      visibility: visible;
      animation: slide-ul 1s forwards;
      @keyframes slide-ul {
        from{
          top: -30px;
        }
        to {
          top: 0px; /* 动画结束位置：元素从上方滑到原位置 */
        }
      }
      li{
        //display: flex;
        //height: 50px;
        color: #707070;
        width: auto;
        min-width: 190px;
        white-space: nowrap;
        margin-right: 30px;
        .title{
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          border-bottom: 1px solid #e3e3e3;
        }
        .name{
          font-size: 18px;
          color: black;
        }
      }
      li:last-child {
        margin-right: 0;
      }
      .li:hover{
        color: #306ADA;
      }
      .iconfont{
        font-size: 25px;
        padding-right: 15px;
      }
    }
    .more_list{
      min-width: 170px !important;
    }
    .active1{
      color: #306ADA;
    }
    .active1:hover::after {
      height: 3px;
      position: absolute;
      left: 50%;
      bottom: 0px;
      border-radius: 50px;
      background: #306ADA;
      content: '';
      -webkit-transition: all .35s;
      color: #306ADA;
      width: 30%;
      transform: translate(-50%, 0);
    }
    .sub_test{
      flex-direction: column;
      align-items: flex-start;
      //border-bottom: 1px solid #e3e3e3;
      //pointer-events: none;
      .li{
        height: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding-top: 20px;
        //pointer-events: auto
      }
    }
    .sub_test2{
      flex-direction: row;
      align-items: center;
      //border-bottom: 1px solid #e3e3e3;
    }
  }
  .nav_list > .menu_item:last-child{
    padding-right: 0;
  }
}
.down_drawer{
  width: 100%;
  height: 370px;
  background-color: #ffffff;
  z-index: 1;
  position: absolute;
  box-shadow: 1px 0px 8px 3px #e4e4e4;
  animation: slide-down 0.5s forwards;
  @keyframes slide-down {
    from{
      top: -368px;
      opacity: 0;
      visibility: hidden;
    }
    to {
      top: 0px; /* 动画结束位置：元素从上方滑到原位置 */
      opacity: 1;
      visibility: visible;
    }
  }
}
.menu_btn {
  width: 80px;
  height: 60px;
  top: 0px;
  right: 0%;
  z-index: 50;
  display: none;
  cursor: pointer;
  //background: linear-gradient(135deg, #73b42c, #1b90cd) center center no-repeat;
  .inner{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 20px;
    }
  }
}
.navigator {
  display: none;
}
.content{
  width: 100%;
  //height: calc(100% - 440px);
  background-color: #fbfbfb;
}
:deep .el-drawer__body{
  padding: 0 !important;
}
:deep .el-collapse-item{
  border-bottom: 1px solid #e6e6e6;
}
:deep .el-collapse-item:nth-child(3){
  .three-li{
    border-bottom: 1px solid #e9e9e9;
    margin: 0 15px 15px;
    .name{
      margin: 5px 0 15px 0;
    }
  }
}
:deep .el-collapse-item__header{
  font-size: 20px;
  font-weight: bold;
  position: relative;
  padding: 30px 20px;
  color: #575757;
}
:deep .el-collapse-item__content{
  padding: 0 20px;
  .three-li{
    display: flex;
    padding: 10px 0 0 0;
    margin: 0 15px 8px;
    flex-wrap: wrap;
    //border-bottom: 1px solid #e9e9e9;
    .name{
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      margin: 5px 0 0 0;
      color: #6b6b6b;
      font-weight: 600;
      .iconfont{
        margin-right: 10px;
        font-size: 18px;
      }
    }
    .child{
      display: flex;
      flex-wrap: wrap;
      li{
        margin: 5px 20px 0 0;
        line-height: 30px;
        color: #7b7b7b;
        display: flex;
        font-size: 14px;
        .iconfont{
          margin-right: 5px;
        }
      }
    }
  }
  .three-li:nth-child(1){
    .iconfont{
      color: #00A9CE;
    }
  }
  .three-li:nth-child(2){
    .iconfont{
      color: #9B26B6;
    }
  }
  .three-li:nth-child(3){
    .iconfont{
      color: #79C000;
    }
  }
  .three-li:nth-child(4){
    .iconfont{
      color: #FF671F;
    }
  }
  .three-li:nth-child(5){
    .iconfont{
      color: #306ada;
    }
  }
  .three-li:last-child{
    border-bottom: unset;
    margin-bottom: 20px;
  }
}
footer {
  width: 100%;
  overflow-x: auto;
  background-color: #393c41;
  color: white;
  animation: slide-up 0.5s forwards;
  @keyframes slide-up {
    from{
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .footer_cont{
    width: 1200px;
    margin: auto;
    padding-top: 40px;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }
  .left {
    //padding-left: 100px;
    width: 50%;
    display: flex;
    flex-direction: column;
    .left_top{
      margin-bottom: 35px;
      .learn_more{
        display: block;
        width: 100%;
        font-size: 36px;
        text-align: left;
        font-weight: normal;
        margin-bottom: 1.5vw;
      }
      .list{
        width: 300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .com{
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            width: 130px;
            height: 130px;
          }
        }
      }
    }
    .left_bottom{
      display: flex;
      flex-direction: row;
      .pc_page{
        margin-right: 20px;
      }
    }
    :deep .el-collapse{
      display: none;
    }
    .title{
      display: block;
      font-size: 15px;
      color: #cfcfcf;
      font-weight: normal;
      margin: 20px 0;
      cursor: pointer;
    }
    .title:hover{
      color: #1b90cd;
    }
    .footer_link{
      display: block;
      font-size: 16px;
      color: #5f6464;
      font-weight: normal;
      margin-bottom: 18px
    }
    > div {
      flex-direction: column;
      display: flex;
      text-align: left;
    }
  }

  .right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    .message_outer{
      text-align: left;
      display: flex;
      align-items: start;
    }
    .message{
      width: 100%;
      .icon{
        margin: 0 0 20px;
        .icon_left{
          display: flex;
          align-items: center;
        }
        .iconfont{
          background-color: #ffffff;
          color: #1090d6;
          padding: 3px;
          border-radius: 20px;
          margin-right: 15px;
          font-size: 20px;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .cont{
          font-size: 14px;
        }
      }
    }
    > div {
      line-height: 1.5em;
      font-size: 15px;
    }
  }

  .copyright{
    font-size: 13px;
    color: #cfcfcf;
    margin: 0 auto 20px;
    text-align: center;
    padding: 0;
  }
}
.back-top{
  right: 50px;
  bottom: 50px;
}
@media screen and (max-width: 1200px){
  .sub_list{
    width: 100% !important;
    padding: 0 20px !important;
  }
  .footer_cont {
    width: calc(100% - 50px) !important;
    margin: 0 25px !important;
  }
  .copyright{
    width: calc(100% - 50px) !important;
    margin: 0 25px 15px !important;
  }
}
@media screen and (max-width: 1024px){
  .logo {
    width: 155px;
  }
  .learn_more{
    font-size: 28px !important;
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 768px){
  .header.open {
    padding: 0px;
    height: 60px;
  }
  .header_open {
    width: 100%;
  }
  .logo {
    width: 135px;
  }
  .nav {
    opacity: 0;
    visibility: hidden;
    height: calc(100vh - 60px);
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    background: #202679;
    display: none;
  }
  .menu_btn {
    display: block;
  }
  .navigator {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    //top: 60px;
    z-index: 3;
    transform: translate(100%, 0);
    //opacity: 0.8;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    //background-color: #f9f9f9;
    ul{
      width: 50%;
      height: 100%;
      background-color: #f9f9f9;
      z-index: 2;
      padding: 50px 35px;
      float: right;
    }
  }
  .bg_hover{
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
    //opacity: 0;
    display: none;
  }
  @keyframes drawer
  {
    0%   {opacity:0.5; right:-100px; top:0px;}
    100%  {opacity:1; right:200px; top:0px;}
  }
  .navigator.active {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  .bg_hover.active {
    transform: translate(0px, 0px);
    //opacity: 1;
    display: block;
  }
  .first_li{
    display: block;
    height: 72px;
    line-height: 72px;
    font-size: 16px;
    color: #2b2e2e;
    font-weight: bold;
    opacity: 0.95;
    -webkit-transition: color 0.3s linear;
    position: relative;
    z-index: 2;
  }
  .second_li{
    display: flex;
    flex-wrap: wrap;
    //background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
    display: none;
    .three_li{
      width: 90%;
      display: flex;
      padding: 10px 0%;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(186, 186, 186, .5);
      margin-left: 5%;
      .iconfont{
        font-size: 20px;
        align-items: center;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }
  .active {
    display: block;
  }
  .footer_cont{
    flex-direction: column;
    width: calc(100% - 50px) !important;
    margin: 0 25px !important;
    .left{
      width: 100%;
      border-bottom: 1px solid #ccd4e3;
      margin-bottom: 10px;
      .left_top {
        margin-bottom: 20px;
      }
      .pc_page{
        display: none;
        .footer_link{
          display: none;
        }
      }
      .title{
        font-size: 16px;
        font-weight: normal;
        color: #5f6464;
      }
      img{
        width: 120px !important;
        height: 120px !important;
      }
    }

    .right {
      width: 100% !important;
      margin-bottom: 10px;
      .icon{
        margin: 10px 0;
      }
    }
    .footer_link{
      font-size: 13px !important;
      //display: none;
    }
  }
  .back-top{
    right: 20px !important;
    bottom: 20px !important;
  }
}
</style>

import { createRouter, createWebHistory  } from 'vue-router'
import HomeIndex from '../views/HomeIndex.vue'
const router = createRouter({
    history: createWebHistory (),
    routes: [
        {
            path: '/',
            name: 'homeIndex',
            component: HomeIndex,
            meta:{keepAlive: true},
            redirect: {
                name: 'HomeView'
            },
            children:[
                {
                    path: '/home/:type?',
                    name: 'HomeView',
                    meta:{title:"首页"},
                    component: () => import('../views/HomeView.vue')
                },
                {
                    path: '/about/:type?',
                    name: 'AboutView',
                    meta:{title:"关于我们"},
                    component: () => import('../views/AboutView.vue')
                },
                {
                    path: '/solution/:type?/:sort?/:solution?',
                    name: 'SolutionPlan',
                    meta:{title:"解决方案"},
                    component: ()=> import('../views/SolutionPlan.vue'),
                },
                {
                    path: '/news/:type?',
                    name: 'NewsView',
                    meta:{title:"新闻中心"},
                    component: ()=> import('../views/NewsView.vue'),
                },
                {
                    path: '/joinUs',
                    name: 'JoinUsView',
                    redirect: {
                        name: 'SocialRecruitment'
                    },
                    meta:{title:"招贤纳士"},
                    component: ()=> import('../views/JoinUsView.vue'),
                    children: [
                        {
                            path: '/joinUs/social',
                            name: 'SocialRecruitment',
                            component: ()=> import('../components/recruitment-page/SocialRecruitment.vue'),
                        },
                        {
                            path: '/joinUs/campus',
                            name: 'CampusRecruitment',
                            component: ()=> import('../components/recruitment-page/CampusRecruitment.vue'),
                        },
   /*                     {
                            path: '/joinUs/detail/:id?',
                            name: 'RecruitmentDetails',
                            component: ()=> import('../components/recruitment-page/RecruitmentDetails.vue'),
                        },*/
                    ]
                },
            ]
        },
        {
            path: '/news/news-list/:newsType?/:route?/:type?/:id?/:bool?',
            name: 'NewsList',
            component: ()=> import('../components/news-page/NewsList.vue'),
        },
        {
            path: '/about/honors',
            name: 'AllQualification',
            component: ()=> import('../components/company-honor/AllQualification.vue'),
        },
        {
            path: '/solution/detail/:id?',
            name: 'CaseDetail',
            component: ()=> import('../components/solution-page/CaseDetail.vue'),
        },

    ],
    scrollBehavior() {
        // 始终滚动到顶部
        return { top: 0 };
    }
})
router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})
export default router

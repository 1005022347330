import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '../public/iconfont/iconfont.css'
import '@wangeditor/editor/dist/css/style.css'
const app = createApp(App)
.use(router)
.use(ElementPlus)
app.mount('#app')

import { axios } from '@/utils/request'
export function CompanyNews(pageNum,pageSize){ // 公司新闻
    return axios({
        url: `/visitor/select/company/news/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function Industrynews(pageNum,pageSize){ // 行业新闻
    return axios({
        url: `/visitor/select/profession/news/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function newsDetail(id){ // 行业新闻
    return axios({
        url: `/visitor/select/one/news/${id}`,
        method: 'get'
    })
}
export function SlideShow(){ // 轮播图
    return axios({
        url: `/home/show/slideshow`,
        method: 'get'
    })
}
/*export function PartnerList(){ // 合作伙伴显示
    return axios({
        url: `/home/show/business/partner`,
        method: 'get'
    })
}*/
export function DevelopmentList(pageNum,pageSize){ // 查询发展历程
    return axios({
        url: `/company/select/development/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
/*export function AdvantageList(){ // 我们的优势
    return axios({
        url: `/company/select/advantage`,
        method: 'get'
    })
}*/
export function CertificationList(pageNum,pageSize){ // 公司资质图片
    return axios({
        url: `/company/get/certification/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function CertificationListType(type,pageNum,pageSize){ // 公司资质图片
    return axios({
        url: `/company/get/certification/${type}/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function SocietyRecruitment(pageNum,pageSize){ // 社会招聘数据
    return axios({
        url: `/recruit/visitor/select/society/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function CampusRecruitment(pageNum,pageSize){ // 校园招聘数据
    return axios({
        url: `/recruit/visitor/select/campus/${pageNum}/${pageSize}`,
        method: 'get'
    })
}
export function RecruitmentSelect(id){ // 招聘单条查询
    return axios({
        url: `/recruit/visitor/select/one/${id}`,
        method: 'get'
    })
}
export function SolutionList(){ // 解决方案标题
    return axios({
        url: `/solution/select/title`,
        method: 'get'
    })
}
export function SingleSolution(id){ // 查询解决方案
    return axios({
        url: `/solution/select/scheme/${id}`,
        method: 'get'
    })
}
export function SingleCase(id){ // 查询单个案列
    return axios({
        url: `/solution/select/case/${id}`,
        method: 'get'
    })
}


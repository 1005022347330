//导入axios
import Axios from 'axios'
import router from '@/router'
const request = Axios.create({
    //指定请求的根路径
    // baseURL:'https://www.yong77.top:444/api/'
    baseURL:'https://www.yong77.top/api/'
})
request.interceptors.request.use(
    config => {
        // config.headers['Authorization'] = localStorage.getItem('token')
        return config
    },
    error => Promise.reject(error)
)
request.interceptors.response.use(
    response => {
        if (response.data.code) {
            switch (response.data.code) {
                case 0:
                    return response
                case -100:
                    // message.warning(response.data.message)
                    return
                case -1240:
                    // router.push('/login')
                    window.name = ''
                    // message.error(response.data.message)
                    return Promise.reject(response.data.message)
                default:
                    // message.error(response.data.message)
                    return Promise.reject(response.data.message)
            }
        }
        return response
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误'
                    break
                case 401:
                    error.message = '会话已过期，请重新登录'
                    router.push('/')
                    window.name = ''
                    break
                case 403:
                    error.message = '拒绝访问'
                    break
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`
                    break
                case 408:
                    error.message = '请求超时'
                    break
                case 500:
                    error.message = '服务器内部错误'
                    break
                case 501:
                    error.message = '服务未实现'
                    break
                case 502:
                    error.message = '网关错误'
                    break
                case 503:
                    error.message = '服务不可用'
                    break
                case 504:
                    error.message = '网关超时'
                    break
                case 505:
                    error.message = 'HTTP版本不受支持'
                    break
                default:
            }
        }
        return Promise.reject(error)
    }
)
export const axios = request

//使用方法,在需要使用的模块导入当前的公共接口模块
//通过request.get()/request.post(),发请求,url路径直接写各个接口名即可,无需写全名了
